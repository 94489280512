<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-0 pb-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="document-header" style="flex: 0">
        <div class="document-header-row px-0 pt-2 pb-2">
          <div style="flex: 0 0 170px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required @userChange="onDateChange" id="date"/>
          </div>
          <div style="flex: 0 0 110px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <month_input v-model="month" label="Місяць нарахування: " required @userChange="onMonthChange" id="month"/>
          </div>
          <div style="flex: 0 0 270px; margin-right: 16px">
            <select_input
                v-model="person_id"
                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                :computed-getter="'get_personal_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Працівник"
                :show-select-on-focus="false"
                :required="true"
                :select-button-object="{
                          name: 'PERSONAL_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список працівників',
                          element_title: 'Працівник',
                          show: true
                        }"
                @selectChange="fillCharge"
            />
          </div>
        </div>
        <div class="document-header-row px-0 pt-1 pb-2">
          <div style="flex: 0 0 296px; margin-right: 16px">
            <select_input
                v-model="vocation_type"
                :clearable="true"
                :import-getter="{ name: 'vocation_type' }"
                :input-value-as-value="false"
                label="Вид відпустки: "
                @selectChange="fillCharge(null)"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px" v-if="!vocation_compensation">
            <date_input v-model="date_start" label="Період з: " required @userChange="fillCharge(null)"
                        id="date_start"/>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px" v-if="!vocation_compensation">
            <date_input v-model="date_end" label="Період по: " required @userChange="fillCharge(null)" id="date_end"/>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px" v-if="vocation_compensation">
            <number_input v-model="compensation_days" label="Днів компенсації" required
                          @userChange="fillCharge(null)"/>
          </div>
        </div>
        <div class="document-header-row px-0 pt-1 pb-3">
          <div style="flex: 0 0 145px; margin-right: 16px">
            <string_input v-model="blank_serial" :required="false" label="Серія бланку: "/>
          </div>
          <div style="flex: 0 0 135px; margin-right: 16px">
            <string_input v-model="blank_number" :required="false" label="Номер бланку: "/>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px" v-if="!vocation_without">
            <number_input v-model="daily_average" v-decimal label="Розмір середньої: " @userChange="calcCharge(null)"/>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Нарахування
                </v-tab>
                <v-tab v-if="!vocation_without">
                  Розрахунок середньоденного
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%;">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.person_id"
                           @click.stop="addCharge"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="!!(this.date && this.month)"
                           class="secondary mr-2" :disabled="!this.date || !this.month || !this.person_id"
                           @click="fillCharge"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Заповнити
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 240px">
                            Вид нарахування
                          </div>
                          <div class="document-table-col" style="flex: 0 0 170px">
                            Група
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px">
                            Рахунок Дт
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px">
                            Рахунок Кт
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 110px">
                            <div class="document-table-col">
                              Дата з
                            </div>
                            <div class="document-table-col">
                              Дата по
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 60px">
                            Днів
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px" v-if="!vocation_without">
                            Середньоденна
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px" v-if="!vocation_without">
                            Результат
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in charges"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ charges.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteCharge(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 240px">
                          <select_input
                              v-model="charge.accrual_id"
                              :computed-action="'SELECT_ACCRUAL_LIST_ELEMENTS'"
                              :computed-getter="'get_accrual_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :action-props="{is_vocation: true}"
                              :select-button-object="{
                                name: 'ACCRUAL_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список видів нарахувань',
                                element_title: 'Вид нарахування',
                                action_props: {is_vocation: true},
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 170px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px">
                          <select_input
                              v-model="charge.debit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px">
                          <select_input
                              v-model="charge.credit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 110px">
                          <div class="document-table-col">
                            <date_input :row_num="1" v-model="charge.date_start" small transparent
                                        @userChange="tableDateChange(charge)"/>
                          </div>
                          <div class="document-table-col">
                            <date_input :row_num="1" v-model="charge.date_end" small transparent
                                        @userChange="tableDateChange(charge)"
                            />
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 60px">
                          <number_input :row_num="charge.row_num" v-model="charge.days" v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px" v-if="!vocation_without">
                          <number_input :row_num="charge.row_num" v-model="charge.daily_average" v-decimal transparent
                                        small
                                        :required="false"
                                        @userChange="calcCharge(charge)"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px" v-if="!vocation_without">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal
                                        transparent small
                                        :input_style="`font-weight: bold; ${getSumColor(charge.result)}`"
                                        :required="false"
                                        @userChange="afterResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               :style="`flex: 0 0 ${vocation_without ? 930 : 1040}px`"
                               style="text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px; padding-left: 10px" v-if="!vocation_without">
                            {{ footer_total.charge.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pa-0" style="height: 100%;" v-if="!vocation_without">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month && this.person_id)"
                           class="success mr-2" :disabled="!this.date || !this.month || !this.person_id"
                           @click.stop="addAverage"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="!!(this.date && this.month && this.person_id)"
                           class="secondary mr-2" :disabled="!this.date || !this.month || !this.person_id"
                           @click="fillAverageTable"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Заповнити
                    </v-btn>
                    <v-btn small
                           :dark="!!(this.date && this.month && this.person_id)"
                           class="teal mr-2" :disabled="!this.date || !this.month || !this.person_id"
                           @click="sortAverageTable"
                    >
                      <v-icon small left>mdi-sort-ascending</v-icon>
                      Пересортувати
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Період з
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Період по
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Календарні дні
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Сума
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in average_table"
                           :key="`result-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ average_table.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteAverage(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <date_input :row_num="1" v-model="charge.date_start" small transparent
                                      @userChange="averageTableDateChange(charge)"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <date_input :row_num="1" v-model="charge.date_end" small transparent
                                      @userChange="averageTableDateChange(charge)"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <number_input :row_num="charge.row_num" v-model="charge.days" v-decimal transparent
                                        small @userChange="averageTableChange"
                                        :required="false"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        small @userChange="averageTableChange"
                                        :required="false"/>
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 290px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px; padding-left: 10px">
                            {{ footer_total.average.days | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col " style="flex: 0 0 110px; padding-left: 10px">
                            {{ footer_total.average.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_PERSONAL_LIST_ELEMENTS,
  SELECT_ACCRUAL_LIST_ELEMENTS,
  SELECT_PERSONAL_LIST_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
  UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
  REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
  CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import salaryChargeAPI from "@/utils/axios/accounting/documents/salary_charge"
import vocationChargeAPI from "@/utils/axios/accounting/documents/vocation_charge"
import {maxBy} from "lodash";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {
  diffMonths,
  beginOfMonth,
  addMothsToStringDate,
  addDaysToStringDate,
  diffDays, endOfMonth, vocation_type_accrual, format_date_from_python
} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'salary_to_pay_accounting_modal_delete'


export default {
  name: "HWP_Modal_Accounting_Vocation",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    month_input: () => import("@/components/accounting/input/document_month_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
  },
  data() {
    return {
      blank_serial: this.item.blank_serial || '',
      blank_number: this.item.blank_number || '',
      month: this.item.month,
      vocation_type: this.item.vocation_type || 'vocation_main',
      person_id: this.item.person_id || null,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
      daily_average: this.item.daily_average || 0,
      compensation_days: this.item.compensation_days || 0,
      charges: [],
      average_table: [],
      footer_total: {
        charge: {result: 0},
        average: {days: 0, result: 0}
      }
    }
  },
  methods: {
    ...mapActions(
        {
          getPersonal: GET_PERSONAL_LIST_ELEMENTS,
        }
    ),
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    afterResultChange() {
      this.getTotals()
    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    getShortName(payload) {
      if (!payload) return ''
      const spliced = payload.split(' ')

      if (spliced.length <= 3) {
        return `${spliced[0]} ${spliced[1].substring(0, 1)}. ${spliced[2].substring(0, 1)}`
      }
      if (spliced.length === 2) {
        return `${spliced[0]} ${spliced[1].substring(0, 1)}`
      }
      if (spliced.length === 1) {
        return `${spliced[0]}`
      }
    },
    getSumColor(result) {
      if ((result || 0) < 0) {
        return 'color: var(--v-error-base)'
      }
      if ((result || 0) > 0) {
        return 'color: var(--v-success-darken1)'
      }
      return 'color: inherit'
    },
    onMonthChange() {
      if (this.month && this.date) {
        this.getStartInfo()
      }
    },
    onDateChange() {
      if (this.month && this.date) {
        this.getStartInfo()
      }
    },
    getTotals() {
      this.footer_total.charge = this.charges.reduce((acc, item) => {
        acc.result += item.result || 0
        return acc
      }, {result: 0})
      this.footer_total.average = this.average_table.reduce((acc, item) => {
        acc.days += item.days || 0
        acc.result += item.result || 0
        return acc
      }, {days: 0, result: 0})
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    roundUp(x) {
      return x % 10 === 0 ? x : x + 10 - x % 10
    },
    round_number(value, precision) {
      return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
    },
    getStartInfo() {
      if (this.date && this.month) {
        if (!this.charts || !this.charts.length) {
          this.getChartsOfAccounts()
        }
        salaryChargeAPI.start_info({month_start: this.month, month_end: this.month})
            .then(response => response.data)
            .then(data => {
              this.start_info = Object.assign({}, data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined || item[filter_row.condition] !== 0) {
              skip = true
            }
          }

          if (item[key] !== null && item[key] !== undefined && item[key] !== 0) {
            skip = true
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument() {
      this.document_state.show = !this.document_state.show
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'month': {id: 'month', text: 'Не заповнений місяць виплати заробітної плати'},
        'vocation_type': {id: 'vocation_type', text: 'Не заповнена вид відпустки'},
        'person_id': {id: 'person_id', text: 'Не заповнений працівник'},
        'date_start': {id: 'date_start', text: 'Не заповнений період початку лікарняного'},
        'date_end': {id: 'date_start', text: 'Не заповнений період закінчення лікарняного'},
      }

      if (!this.vocation_without) {
        header['daily_average'] = {id: 'daily_average', text: 'Не заповнений розмір середньоденного заробітку'}
      }

      if (this.vocation_compensation) {
        header['compensation_days'] = {id: 'compensation_days', text: 'Не заповнена кількість днів компенсації'}
      }


      const table_charge = {
        'accrual_id': {table: 'Нарахування', text: 'Не заповнений вид нарахування'},
        'accrual_group': {table: 'Нарахування', text: 'Не заповнена група нарахування'},
        'date_start': {table: 'Нарахування', text: 'Не заповнена дата початку'},
        'date_end': {table: 'Нарахування', text: 'Не заповнена дата закінчення'},
        'month': {table: 'Нарахування', text: 'Не заповнений місяць'},
        'debit_chart_of_account_id': {table: 'Нарахування', text: 'Не заповнений рахунок Дт'},
        'credit_chart_of_account_id': {table: 'Нарахування', text: 'Не заповнений рахунок Кт'},
      }

      if (!this.vocation_without) {
        header['daily_average'] = {table: 'Нарахування', text: 'Не заповнений розмір середньої'}
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.checkDocumentTable(table_charge, 'Нарахування', 'charges')

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = true
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    fillAverageTable() {
      vocationChargeAPI.fill_average_table(
          {
            date_start: this.date_start,
            date_end: this.date_end,
            person_id: this.person_id
          }
      )
          .then(response => response.data)
          .then(data => {
            this.average_table = data.table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.getTotals()
            this.calcAverage()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },

    tableDateChange(row) {
      if (!row) return
      if (row.date_start && row.date_end) {
        row.days = diffDays(row.date_end, row.date_start) + 1
      } else {
        row.days = 0
      }
      this.calcCharge(row)
      this.getTotals()
    },
    averageTableDateChange(row) {
      if (!row) return
      if (row.date_start && row.date_end) {
        row.days = diffDays(row.date_end, row.date_start) + 1
      } else {
        row.days = 0
      }
      this.calcAverage()
    },
    averageTableChange() {
      this.calcAverage()
    },
    calcAverage() {
      this.getTotals()

      this.daily_average = this.round_number(((this.footer_total.average.result / this.footer_total.average.days) || 0), 2)
      this.calcCharge()
    },
    fillCharge(payload) {
      if (payload === null && this.vocation_type === 'vocation_compensation') {
        this.date_start = beginOfMonth(this.month)
        this.date_end = endOfMonth(this.month)
      }
      const value = (payload || {}).value || (payload || {}).id || null
      const months = diffMonths(this.date_end, this.date_start, true)
      const start_month = addMothsToStringDate(beginOfMonth(this.date_start), -1)
      let days = diffDays(this.date_end, this.date_start)
      const local_person_id = value ? value : this.person_id

      if (!local_person_id || !this.vocation_type || !this.date_start || !this.date_end) return

      let days_by_organization = 99999

      const history = []
      const rows = []

      this.charges = []

      const accrual_obj = this.start_info.accruals.find(i => i.system_name === vocation_type_accrual[this.vocation_type]) || {}

      for (let i = 1; i <= months; i++) {
        const current_month = addMothsToStringDate(start_month, i)
        const end_month = endOfMonth(current_month)

        let date_start = null
        let date_end = null

        if (i === 1) {
          history.push(this.date_start)
          date_start = this.date_start
        } else {
          date_start = addDaysToStringDate(history[history.length - 1], 1)
          days -= 1
        }

        if (days_by_organization > 0) {
          date_end = addDaysToStringDate(date_start, Math.min(days_by_organization, days))

          if (beginOfMonth(date_end) === current_month) {
            days_by_organization = 0
          } else {
            days_by_organization -= diffDays(end_month, date_start) + 1
            date_end = end_month
          }
          days -= diffDays(date_end, date_start)
          rows.push(
              {
                date_start,
                date_end,
                by_organization: true,
                days: diffDays(date_end, date_start) + 1,
                month: current_month,
                percent: this.percent || 0,
                daily_average: this.daily_average || 0,
                accrual_id: accrual_obj.id,
                accrual_group: accrual_obj.accrual_group
              }
          )
          history.push(date_end)
        }
      }

      const charge_661_id = '661'
      const chart_661 = this.charts.find(c => c.code === charge_661_id)

      const person_data = (this.start_info?.persons || []).find(item => item.person_id === local_person_id)
      const person_chart_of_account = (person_data || {}).chart_of_account_id

      rows.forEach(item => {
        const result = this.round_number(item.daily_average * item.days, 2)
        this.charges.push(
            {
              accrual_id: item.accrual_id,
              accrual_group: item.accrual_group,
              credit_chart_of_account_id: chart_661.id,
              debit_chart_of_account_id: person_chart_of_account,
              date_start: item.date_start,
              date_end: item.date_end,
              days: item.days,
              month: item.month,
              daily_average: item.daily_average,
              result,
              row_num: this.getNewRowNum('charges'),
            }
        )
      })
      this.getTotals()
    },
    calcCharge(row) {
      if (row) {
        row.daily_average = row.daily_average || 0
        row.result = this.round_number(row.daily_average * row.days, 2)
      } else {
        this.charges.forEach(item => {
          item.daily_average = this.daily_average || 0
          item.result = this.round_number(item.daily_average * item.days, 2)
        })
      }

      this.getTotals()
    },

    addCharge() {
      const charge_661_id = '661'
      const chart_661 = this.charts.find(c => c.code === charge_661_id)

      const person_data = (this.start_info?.persons || []).find(item => item.person_id === this.person_id)
      const person_chart_of_account = (person_data || {}).chart_of_account_id

      const accrual_obj = this.start_info.accruals.find(i => i.system_name === vocation_type_accrual[this.vocation_type]) || {}

      this.charges.push(
          {
            accrual_id: accrual_obj.id,
            accrual_group: accrual_obj.accrual_group,
            date_start: null,
            date_end: null,
            days: 0,
            month: null,
            daily_average: this.daily_average || 0,
            debit_chart_of_account_id: person_chart_of_account || null,
            credit_chart_of_account_id: chart_661.id || null,
            result: 0,
            row_num: this.getNewRowNum('charges'),
          }
      )
    },
    addAverage() {
      this.average_table.push(
          {
            date_start: null,
            date_end: null,
            days: 0,
            result: 0,
            row_num: this.getNewRowNum('average_table'),
          }
      )
    },

    sortAverageTable() {
      this.average_table = this.average_table
          .map(i => Object.assign({month_int: +i.date_start.replaceAll('-', '')}, i))
          .sort((a, b) => a.month_int - b.month_int)
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.date = local_data.date || null
      this.date_start = local_data.date_start || null
      this.date_end = local_data.date_end || null
      this.number = local_data.number || null
      this.month = local_data.month || null
      this.vocation_type = local_data.vocation_type || null
      this.person_id = local_data.person_id || null
      this.charges = []
      this.itemId = payload.id || null
    },
    clearDocument() {
      this.charges = []
      this.getTotals()
    },

    deleteCharge(payload) {
      this.charges.splice(this.charges.indexOf(payload), 1)
      this.getTotals()
    },
    deleteAverage(payload) {
      this.average_table.splice(this.average_table.indexOf(payload), 1)
      this.calcAverage()
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа відпустка № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    deletePersonDialog(person) {
      const payload = {
        text: `Вилучити працівника ${person.full_name} із документа?`,
        accept_button: true,
        id: 'salary_charge_delete_person',
        payload: person
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    get_payload_for_crud() {
      const payload = {
        date: this.date,
        month: this.month,
        vocation_type: this.vocation_type,
        date_start: this.date_start,
        date_end: this.date_end,
        person_id: this.person_id,
        daily_average: this.daily_average,
        blank_number: this.blank_number,
        blank_serial: this.blank_serial,
        charge_table: this.charges,
        average_table: this.average_table,
        compensation_days: this.compensation_days
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_ACCRUAL_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_PERSONAL_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },
    fetch_data_by_id() {
      if (this.isNew) return

      vocationChargeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.tab = 0
            this.date = data.date
            this.number = data.number
            this.month = data.month
            this.date_start = data.date_start
            this.date_end = data.date_end
            this.blank_serial = data.blank_serial
            this.blank_number = data.blank_number
            this.vocation_type = data.vocation_type
            this.compensation_days = data.compensation_days
            this.person_id = data.person_id
            this.prev_document = data.prev_document
            this.daily_average = data.daily_average || 0

            this.charges = data.charge_table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.average_table = data.average_table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.getTotals()
          })
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
                  .then(() => {
                    if (!this.isNew) {
                      this.getStartInfo()
                    } else {
                      if (this.date && this.month) {
                        this.getStartInfo()
                      }
                    }
                  })
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              // if (payload.id === "charge_refill_id") {
              //   if (payload.answer) {
              //     this.fillDocumentByAll()
              //   }
              // }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      personSelection: 'get_personal_list',
    }),
    vocation_without() {
      return (this.vocation_type || '').indexOf('without') > 0
    },
    vocation_compensation() {
      return this.vocation_type === 'vocation_compensation'
    },
    shortTables() {
      switch (this.operation) {
        case 'pdfo':
          return true
        case 'taxes':
          return true
        case 'contribution':
          return true
        case 'salary':
          return true
      }

      return false
    },
    calcTaxes() {
      switch (this.operation) {
        case 'advance_calculated':
          return true
        case 'advance_fixed':
          return true
      }

      return false
    },
    showTabTaxes() {
      switch (this.operation) {
        case 'advance_calculated':
          return true
        case 'advance_fixed':
          return true
        case 'pdfo':
          return true
        case 'taxes':
          return true
        case 'salary':
          return true
      }

      return false
    },
    showTabContribution() {
      switch (this.operation) {
        case 'advance_calculated':
          return true
        case 'advance_fixed':
          return true
        case 'contribution':
          return true
        case 'taxes':
          return true
        case 'salary':
          return true
      }

      return false
    },
    showTabCharge() {
      switch (this.operation) {
        case 'contribution':
          return false
        case 'taxes':
          return false
        case 'pdfo':
          return false
      }

      return true
    },

    privilege_percent() {
      if (this.privilege_type) {
        const position = this.privilege_type.lastIndexOf('_')
        return this.privilege_type.substr(position + 1)
      } else {
        return '0'
      }
    },
  }
}
</script>
